import React from "react";
import './Footer.css';
import { FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import styled, { css } from "styled-components";

const Icons = css`
    font-size: clamp(1rem, 6vw, 2rem);
    margin-right: 1.5rem;
    color: var(--white);
`;

const Facebook = styled(FaFacebookF)`
    ${Icons}
`;

const LinkedIn = styled(FaLinkedinIn)`
    ${Icons}
`;

const Instagram = styled(FaInstagram)`
    ${Icons}
`;

export default function Footer() {
    return (
        <section className="footer-section" id="contact">
            <div className="footer-container">
                <div className="footer-top">
                    <div className="quote">
                        <h3>The Trinity <br></br> Centre</h3>
                        <p>24 Cambridge Science Park, Milton Road, Cambridge, CB4 0FN, UK</p>
                    </div>
                    <div className="footer-info">
                        <h4>Conference Enquiries</h4>
                        <p>01223 395800</p>
                        <p>bookings@thetrinitycentre.uk.com</p>
                    </div>
                    <div className="footer-info">
                        <h4>Restaurant Enquiries</h4>
                        <p>01223 395800</p>
                        <p>restaurant@thetrinitycentre.uk.com</p>
                    </div>
                </div>
                <div className="footer-bottom">
                    <div className="social-icons">
                        <a href="https://www.facebook.com/Trinity-Centre-Cambridge-888286111220729/" rel="noopener noreferrer" target="_blank"><Facebook /></a>
                        <a href="https://www.linkedin.com/company-beta/11092100/" rel="noopener noreferrer" target="_blank"><LinkedIn /></a>
                        <a href="https://www.instagram.com/trinitycentrecambridge?igsh=aWYyaTBlNzB1eHJw" rel="noopener noreferrer" target="_blank"><Instagram /></a>
                    </div>
                </div>
            </div>
        </section>
    );
}