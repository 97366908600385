import science1 from '../images/science1.jpg';
import science2 from '../images/science2.png';
import science3 from '../images/science3.jpg';

export const descSection = {
    heading: 'Ready for your next adventure?',
    parOne: "Welcome to the Cambridge home of hands-on science fun. Unleash your creativity and work together to explore the wonders of science, technology, engineering and mathematics.",
    parTwo: "With playful exhibits that invite curiosity, explosive science shows that captivate young imaginations, and interactive workshops that turn learning into an adventure.",
    parThree: "We are here to support children and young people build a lifelong love of science through inspirational hands-on activities.",
    image: science1,
    alt: 'science experiment',
    reverse: false,
    path: 'https://www.cambridgesciencecentre.org/',
    label: 'Learn More'
};

export const familySection = {
    heading: 'Opening Times - Families and Public',
    parOne: "Saturday-Sunday (Term Times): 10:00 – 16:00",
    parTwo: "Monday-Sunday (School Holidays): 08:30 – 10:30",
    image: science2,
    alt: 'science experiment',
    reverse: true
};

export const schoolSection = {
    heading: 'Opening Times - Schools',
    parOne: "Monday-Friday (Term Times): 09:30 - 11:30 & 13:00 - 15:00",
    parTwo: "",
    image: science3,
    alt: 'science experiment',
    reverse: false
};