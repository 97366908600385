import React from "react";
import Hero from "../Hero";
import { homeSlides } from "../data/SliderData";
import InfoSection from "../InfoSection";
import { aboutSection, baristaSection, conferenceSection, gymSection, barberSection, scienceSection } from "../data/homeData";

export default function Home() {
    return (
        <>
            <Hero slides={homeSlides}/>
            <InfoSection section={aboutSection}/>
            <InfoSection section={baristaSection}/>
            <InfoSection section={conferenceSection}/>
            <InfoSection section={scienceSection}/>
            <InfoSection section={gymSection} anchor={true}/>
            <InfoSection section={barberSection}/>
        </>
    );
}