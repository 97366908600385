import React from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { FaTimes } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Link as LinkR } from "react-scroll";

const SidebarContainer = styled.div`
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: var(--fill);
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '1' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
`;

const Icon = styled.div`
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;

const CloseIcon = styled(FaTimes)`
    color: var(--white);
`;

const SidebarWrapper = styled.div``;

const SidebarMenu = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 80px);
    text-align: center;
    margin-bottom: 4rem;

    @media screen and (max-width: 480px) {
        grid-template-rows: repeat(6, 60px);
    }
`;

const SidebarLink = styled(Link)`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        color: #000d1a;
    }
`;

const SidebarAnchor = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 1.5rem;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
    transition: 0.2s ease-in-out;

    &:hover {
        color: #000d1a;
    }
`;

const BtnWrap = styled.div`
    display: flex;
    justify-content: center;
`

const Sidebar = ({ isOpen, toggle }) => {
    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to="/">Home</SidebarLink>
                    <SidebarLink to="/trinity-baristas">Trinity Baristas</SidebarLink>
                    <SidebarLink to="/waterside-suite">Waterside Suite</SidebarLink>
                    <SidebarLink to="/cambridge-science-centre" className="nav-menu-link">Cambridge Science Centre</SidebarLink>
                    <SidebarAnchor href="https://www.revolutionhealthfitness.co.uk/" rel="noopener noreferrer" target="_blank">Revolution Gym</SidebarAnchor>
                    <SidebarLink to="/park-barbers">Park Barbers</SidebarLink>
                </SidebarMenu>
                <BtnWrap>
                    <Button primary={true} round={true} big={true}>
                        <LinkR to="contact" spy={true} smooth={true} offset={50} duration={500}>Contact Us</LinkR>
                    </Button>
                </BtnWrap>
            </SidebarWrapper>
        </SidebarContainer>
    );
};

export default Sidebar;