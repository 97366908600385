import barbers1 from '../images/barbers1.jpg';
import barbers2 from '../images/barbers2.jpg';

export const descSection = {
    heading: 'Park Barbers',
    parOne: "Charlie and Tony have many years of experience between them, and can cater to all hair types. If you want to look sharp, Park Barbers have you covered.",
    parTwo: "You can book an appointment via Booksy using the link below or call us on 07514075949.",
    image: barbers1,
    alt: 'barber space',
    reverse: false,
    path: 'https://booksy.com/en-gb/48267_park-barbers_barber_148251_cambridge',
    label: 'Book Now'
};

export const openingSection = {
    heading: 'Opening Times',
    parOne: "Monday - Friday: 09:00 - 18:00",
    parTwo: "Saturday - Sunday / Bank Holidays: Closed",
    image: barbers2,
    alt: 'clippers',
    reverse: true
};