import React from "react";
import Hero from "../Hero";
import { barberSlides } from "../data/SliderData";
import InfoSection from "../InfoSection";
import { descSection, openingSection } from "../data/BarberData";

export default function Barbers () {
    return (
        <>
            <Hero slides={barberSlides} anchor={true}/>
            <InfoSection section={descSection} anchor={true}/>
            <InfoSection section={openingSection}/>
        </>
    );
}