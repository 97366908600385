import conference1 from '../images/conference1.jpg';
import conference2 from '../images/conference2.jpg';
import conference3 from '../images/conference3.jpg';

export const descSection = {
    heading: 'Waterside Suite',
    parOne: "With a beautiful lake view, our waterside suite is built to accommodate anything from one-to-one meetings, conferences and off-site training.",
    parTwo: "Our dedicated team are here to assist with the smooth running of your conference and ensure that all your needs are met.",
    parThree: "In addition, we provide a catering service with a range of food options to suit your event.",
    image: conference1,
    alt: 'conference room',
    reverse: false,
    path: './conferencemenu.pdf',
    label: 'Catering Menu'
};

export const facilitiesSection = {
    heading: 'Facilities',
    parOne: "Projector hire: £65 + VAT",
    parTwo: "Flipchart hire: £10 + VAT",
    parThree: `50" LED screen hire: £100 + VAT`,
    parFour: "Delegate notepaper and pen: £1.50 + VAT per attendee",
    image: conference2,
    alt: 'conference room',
    reverse: true
};

export const openingSection = {
    heading: 'Opening Times',
    parOne: "Monday - Friday: 08:30 - 17:00",
    image: conference3,
    alt: 'conference room',
    reverse: false
};