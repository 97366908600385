import React from "react";
import Hero from "../Hero";
import { conferenceSlides } from "../data/SliderData";
import { descSection, facilitiesSection, openingSection} from '../data/ConferenceData';
import InfoSection from "../InfoSection";

export default function Conference () {
    return (
        <>
            <Hero slides={conferenceSlides} />
            <InfoSection section={descSection} anchor={true} />
            <InfoSection section={facilitiesSection} />
            <InfoSection section={openingSection} />
        </>
    );
}