import baristas1 from '../images/baristas1.jpg';
import baristas2 from '../images/baristas2.jpg';
import baristas3 from '../images/baristas3.jpg';

export const descSection = {
    heading: 'Welcome to our restaurant',
    parOne: "We pride ourselves on using local suppliers in providing you with fresh ingredients daily along with home produced cakes, sandwiches, baguettes and salads.",
    parTwo: "Trinity Baristas is the ideal place to meet clients, work colleagues for an informal meeting, we offer free wi-fi to all our guests whilst using the café. The restaurant is available for hire for afternoon and evening events, and can host up to 200 persons.",
    parThree: "Our restaurant provides eat in and takeaway options for all our food offers, and you can place orders using our online ordering platform.",
    image: baristas1,
    alt: 'coffe shop floor',
    reverse: false,
    path: 'https://order.oolio.io/revolutionhealthfitness',
    label: 'Place Order'
};

export const lunchSection = {
    heading: 'Lunch service',
    parOne: "A table-served lunch service operates daily Monday to Friday.",
    parTwo: "Breakfast: 08:30 – 10:30",
    parThree: "Lunch: 12:00 – 14:00",
    parFour: "Check out our restaurant menu for more information.",
    image: baristas2,
    alt: 'dessert',
    reverse: true,
    path: './baristasmenu.pdf',
    label: 'Menu'
};

export const openingSection = {
    heading: 'Opening Times',
    parOne: "Monday-Friday: 08:30 - 16:30",
    parTwo: "Online orders: 08:30 - 14:00",
    image: baristas3,
    alt: 'coffee',
    reverse: false
};