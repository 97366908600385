import React from "react";
import Hero from "../Hero";
import { scienceSlides } from "../data/SliderData";
import InfoSection from "../InfoSection";
import { descSection, familySection, schoolSection } from "../data/ScienceData";

export default function Science() {
    return (
        <>
            <Hero slides={scienceSlides} anchor={true}/>
            <InfoSection section={descSection} anchor={true}/>
            <InfoSection section={familySection}/>
            <InfoSection section={schoolSection}/>
        </>
    );
}