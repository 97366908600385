import React from "react";
import Hero from "../Hero";
import { baristaSlides } from "../data/SliderData";
import InfoSection from "../InfoSection";
import { descSection, lunchSection, openingSection } from "../data/BaristaData";

export default function baristas() {
    return (
        <>
            <Hero slides={baristaSlides} anchor={true}/>
            <InfoSection section={descSection} anchor={true}/>
            <InfoSection section={lunchSection} anchor={true}/>
            <InfoSection section={openingSection}/>
        </>
    );
}