import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import ScrollToTop from "./ScrollToTop.js";
import Home from "./Components/Pages/Home";
import Baristas from "./Components/Pages/Baristas.js";
import Conference from "./Components/Pages/Conference.js";
import Science from "./Components/Pages/Science.js";
import Barbers from "./Components/Pages/Barbers.js";
import Navbar from "./Components/Navbar";
import Sidebar from "./Components/Sidebar";
import Footer from "./Components/Footer";

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar toggle={toggle} />
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/trinity-baristas" element={<Baristas />} />
          <Route path="/waterside-suite" element={<Conference />} />
          <Route path="/cambridge-science-centre" element={<Science />} />
          <Route path="/park-barbers" element={<Barbers />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
